import Epic from './Epic';
import WorkItem from './WorkItem';
import Phase from './ProjectPhase';
import Milestone from './Milestone';
import Calc from '../Utilities/calculators';
import ResourceTimeline from './ResourceTimeline';
import { currencyFormatter } from 'Utilities/currency-utils';
import NonLaborExpense from './NonLaborExpense';
import Basic from './Basic';
import Comment from './Comment';
import moment from 'moment';
import KeyResult from './KeyResult';
import ProjectProfile from './ProjectProfile';

function calculatePartialDollars(
    dateFilter,
    originalAmount,
    estimatedStartDate,
    estimatedCompletionDate,
) {
    if (!originalAmount) {
        return 0;
    }
    const dateOverlaps = Calc.dateRangeOverlaps(
        estimatedStartDate,
        estimatedCompletionDate,
        dateFilter?.startDate,
        dateFilter?.endDate,
    );

    if (!dateOverlaps) {
        // timeline does not satisfy date filter, exclude from total
        return 0;
    }

    const projectDurationInDays = Calc.durationInCalendarDays(
        estimatedStartDate,
        estimatedCompletionDate,
    );

    const amountPerDay = originalAmount / projectDurationInDays;

    const partialDurationDate = Calc.getFilteredDatesProject(
        estimatedStartDate,
        estimatedCompletionDate,
        dateFilter,
    );

    const partialDuration = Calc.durationInCalendarDays(
        partialDurationDate.startDate,
        partialDurationDate.endDate,
    );

    const partialDollars = partialDuration * amountPerDay;

    return Math.round(partialDollars);
}

function calculatePartialEstimatedMarginAmount(
    dateFilter,
    estimatedStartDate,
    estimatedCompletionDate,
    estimatedValue,
    estimatedCost,
) {
    let partialEstimatedValue = calculatePartialDollars(
        dateFilter,
        estimatedValue,
        estimatedStartDate,
        estimatedCompletionDate,
    );
    let partialEstimatedCost = calculatePartialDollars(
        dateFilter,
        estimatedCost,
        estimatedStartDate,
        estimatedCompletionDate,
    );

    if (partialEstimatedValue && partialEstimatedCost) {
        return partialEstimatedValue - partialEstimatedCost;
    } else {
        return 0;
    }
}

export default class Project extends Basic {
    projectDoneStatus = 3;
    workItemDoneStatus = WorkItem.doneStatusId;

    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

         // specifies how projects are tracked(points, hours, work items, or schedule)
         this.trackProjectsBy = obj.trackProjectsBy;

        this.nonLaborExpenses = [];
        if (obj.nonLaborExpenses?.length) {
            obj.nonLaborExpenses.forEach((cost) => {
                this.nonLaborExpenses.push(new NonLaborExpense(cost));
            });
        }

        // the list of characteristics associated with this project
        this.characteristics = obj.characteristics || [];

        this.color = obj.color || '';

        // the collection of epics found in this project
        this.epics = obj.epics?.length ? obj.epics.map((a) => new Epic(a)) : [];

        // the collection of workItems found in this project that are outside of an epic
        this.workItems = obj.workItems?.length
            ? obj.workItems.map((a) => new WorkItem(a))
            : [];

        (this.projectPhases = obj.projectPhases?.length
            ? obj.projectPhases.map((x) => new Phase(x))
            : []),
            (this.estimatedBusinessValue = obj.estimatedBusinessValue || 2);
        this.estimatedBusinessValueName = obj.estimatedBusinessValueName;
        this.estimatedLevelOfEffort = obj.estimatedLevelOfEffort || 3;
        this.estimatedLevelOfEffortName = obj.estimatedLevelOfEffortName;

        // an optional collection of Department to group work items
        this.linesOfBusiness = obj.linesOfBusiness || [];

        // the collection of resource timelines for this project
        this.resourceTimelines = obj.resourceTimelines?.length
            ? obj.resourceTimelines.map((a) => new ResourceTimeline(a))
            : [];

        // the collection of project profiles for this project
        this.projectProfiles = obj.projectProfiles?.length
            ? obj.projectProfiles.map((a) => new ProjectProfile(a))
            : [];

        this.rank = obj.rank;

        this.milestones = obj.milestones?.length
            ? obj.milestones.map((a) => new Milestone(a))
            : [];

        // the name of the work
        this.name = obj.name || '';

        // the description of the work
        this.description = obj.description || '';

        // the objective of the work
        this.objective = obj.objective || '';

        // an optional point estimate of the work
        this.pointsEstimate = obj.pointsEstimate || 0;

        // an optional point estimate of completed work on this item
        this.pointsComplete = obj.pointsComplete || 0;

        // an optional hour estimate of the work
        this.hoursEstimate = obj.hoursEstimate || 0;

        // an optional tracking of hours complete (work may be estimated at
        // one number, but tracked hours could exceed estimate or end early)
        this.hoursComplete = obj.hoursComplete || 0;

        this.risk = Number.isInteger(obj.risk) ? obj.risk : 0;

        this.riskName = obj.riskName || '';

        this.status = Number.isInteger(obj.status) ? obj.status : 0;

        this.statusName = obj.statusName || '';

        this.isPrivate = obj.isPrivate;

        // an optional collection of arbitrary labels to group work items
        this.labels = obj.labels || [];

        // a comment thread about this work
        this.comments = obj.comments?.length
            ? obj.comments.map((a) => new Comment(a))
            : [];

        // support integrations (JIRA)
        this.integrationId = obj.integrationId;

        this.integrationKey = obj.integrationKey;

        this.projectKey = obj.projectKey || '';

        // the collection of key results to track whether the work is successful
        this.keyResults = obj.keyResults?.length
            ? obj.keyResults.map((a) => new KeyResult(a))
            : [];

        // the estimated start date (this is the date a team or person _thinks_ they will start)
        this.estimatedStartDate = obj.estimatedStartDate
            ? new Date(obj.estimatedStartDate)
            : null;

        // the estimated completion date (this is the date a team or person _thinks_ they will complete)
        this.estimatedCompletionDate = obj.estimatedCompletionDate
            ? new Date(obj.estimatedCompletionDate)
            : null;

        // the date the work actually starts
        this.actualStartDate = obj.actualStartDate
            ? new Date(obj.actualStartDate)
            : null;

        // the date the work was actually completed
        this.actualCompletionDate = obj.actualCompletionDate
            ? new Date(obj.actualCompletionDate)
            : null;

        // the estimated cost (this is the amount a team or person _thinks_ it will cost)
        this.estimatedCost = obj.estimatedCost || 0;

        // the estimated value (this is the amount a team or person _thinks_ it will return in value)
        this.estimatedValue = obj.estimatedValue || 0;

        // the actual cost of the project
        this.actualCost = obj.actualCost || 0;

        // the actual value of the project
        this.actualValue = obj.actualValue || 0;

    }

    // the margin between actual value and actual cost
    get actualMarginAmount() {
        if (this.actualValue && this.actualCost) {
            return this.actualValue - this.actualCost;
        } else {
            return 0;
        }
    }

    // the margin between estimated value and estimated cost
    get estimatedMarginAmount() {
        if (this.estimatedValue && this.estimatedCost) {
            return this.estimatedValue - this.estimatedCost;
        } else {
            return 0;
        }
    }

    // the average cost of a story point for work already completed
    get averageActualCostPerPoint() {
        return this.actualCost && this.totalEstimatedPoints
            ? this.actualCost / this.totalEstimatedPoints
            : 0;
    }

    get averageEstimatedCostPerPoint() {
        return this.estimatedCost && this.totalEstimatedPoints
            ? this.estimatedCost / this.totalEstimatedPoints
            : 0;
    }

    get averagePlannedCostPerPoint() {
        return this.plannedCost && this.totalEstimatedPoints
            ? this.plannedCost / this.totalEstimatedPoints
            : 0;
    }

    // duration in calendar days
    get estimatedDurationInCalendarDays() {
        if (this.estimatedStartDate && this.estimatedCompletionDate) {
            return Calc.durationInCalendarDays(
                this.estimatedStartDate,
                this.estimatedCompletionDate,
            );
        } else {
            return 0;
        }
    }

    // duration in work days (M-F)
    // TODO: delete? not used anywhere but ProgressSummary, which itself is not used
    get estimatedDurationInWorkDays() {
        if (this.estimatedStartDate && this.estimatedCompletionDate) {
            return Calc.durationInWorkDays(
                this.estimatedStartDate,
                this.estimatedCompletionDate,
            );
        } else {
            return 0;
        }
    }

    // actual duration in work days (M-F)
    // TODO: delete? not used anywhere
    get actualDurationInWorkDays() {
        if (this.actualStartDate && this.actualCompletionDate) {
            return Calc.durationInWorkDays(
                this.actualStartDate,
                this.actualCompletionDate,
            );
        } else {
            return 0;
        }
    }

    // duration in calendar days
    // TODO: delete? not used anywhere
    get actualDurationInCalendarDays() {
        if (this.actualStartDate && this.actualCompletionDate) {
            return Calc.durationInCalendarDays(
                this.actualStartDate,
                this.actualCompletionDate,
            );
        } else {
            return 0;
        }
    }

    get percentageCompleteByPoints() {
        if (this.totalPointsComplete && this.totalEstimatedPoints) {
            return (
                (
                    (this.totalPointsComplete / this.totalEstimatedPoints) *
                    100
                )?.toFixed(2) * 1
            );
        } else {
            return 0;
        }
    }

    get percentageCompleteByHours() {
        if (this.totalHoursComplete && this.totalHoursEstimate) {
            return (
                (
                    (this.totalHoursComplete / this.totalHoursEstimate) *
                    100
                )?.toFixed(2) * 1
            );
        } else {
            return 0;
        }
    }

    get actualCostWithDateFilter() {
        return (dateFilter) => {
            return calculatePartialDollars(
                dateFilter,
                this.actualCost,
                this.estimatedStartDate,
                this.estimatedCompletionDate,
            );
        };
    }

    get estimatedCostWithDateFilter() {
        return (dateFilter) => {
            return calculatePartialDollars(
                dateFilter,
                this.estimatedCost,
                this.estimatedStartDate,
                this.estimatedCompletionDate,
            );
        };
    }

    get estimatedValueWithDateFilter() {
        return (dateFilter) => {
            return calculatePartialDollars(
                dateFilter,
                this.estimatedValue,
                this.estimatedStartDate,
                this.estimatedCompletionDate,
            );
        };
    }

    get estimatedMarginAmountWithDateFilter() {
        return (dateFilter) => {
            return calculatePartialEstimatedMarginAmount(
                dateFilter,
                this.estimatedStartDate,
                this.estimatedCompletionDate,
                this.estimatedValue,
                this.estimatedCost,
            );
        };
    }

    // the planned cost based on velocity of the team to accomplish points and the
    // estimated points for all work required
    get plannedCost() {
        var total = 0;

        if (this.resourceTimelines?.length) {
            this.resourceTimelines.forEach((resourceTimeline) => {
                total += resourceTimeline.totalCost;
            });
        }

        if (this.projectProfiles?.length) {
            this.projectProfiles.forEach((projectProfile) => {
                total += projectProfile.totalCost;
            });
        }

        total += this.nonLaborExpensesTotal;

        return total;
    }

    // TODO: duplicates plannedCostBetweenTwoDates; consider consolidating
    get plannedCostWithDateFilter() {
        return (dateFilter) => {
            if (dateFilter.startDate && dateFilter.endDate) {
                return this.plannedCostBetweenTwoDates(
                    dateFilter.startDate,
                    dateFilter.endDate,
                );
            } else {
                return this.plannedCost;
            }
        };
    }

    get plannedCostPercentage() {
        return this.plannedCost && this.estimatedCost
            ? ((this.plannedCost / this.estimatedCost) * 100).toFixed(0) * 1
            : 0;
    }

    // the planned margin amount based on the planned revenue and the planned cost
    get plannedMarginAmount() {
        if (this.plannedRevenue && this.plannedCost) {
            return this.plannedRevenue - this.plannedCost;
        } else {
            return 0;
        }
    }

    get plannedMarginAmountWithDateFilter() {
        return (dateFilter) => {
            if (dateFilter.startDate && dateFilter.endDate) {
                const plannedRevenue = this.plannedRevenueBetweenTwoDates(
                    dateFilter.startDate,
                    dateFilter.endDate,
                );

                const plannedCost = this.plannedCostBetweenTwoDates(
                    dateFilter.startDate,
                    dateFilter.endDate,
                );

                if (plannedRevenue && plannedCost) {
                    return plannedRevenue - plannedCost;
                }

                return 0;
            } else {
                const plannedRevenue = this.plannedRevenue;
                const plannedCost = this.plannedCost;
                if (plannedRevenue && plannedCost) {
                    return plannedRevenue - plannedCost;
                }

                return 0;
            }
        };
    }

    // the planned margin percentage based on the estimated value and the planned cost
    get plannedMarginPercentage() {
        if (this.plannedMarginAmount && this.plannedRevenue) {
            return Math.round(
                (this.plannedMarginAmount / this.plannedRevenue) * 100,
            );
        } else {
            return 0;
        }
    }

    // the planned cost based on velocity of the team to accomplish points and the
    // estimated points for all work required
    get plannedRevenue() {
        var total = 0;

        if (this.resourceTimelines?.length) {
            this.resourceTimelines.forEach((resourceTimeline) => {
                total += resourceTimeline.totalRevenue;
            });
        }

        if (this.projectProfiles?.length) {
            this.projectProfiles.forEach((projectProfile) => {
                total += projectProfile.totalRevenue;
            });
        }

        return total;
    }

    // TODO: duplicates plannedRevenueBetweenTwoDates; consider consolidating
    get plannedRevenueWithDateFilter() {
        return (dateFilter) => {
            return this.plannedRevenueBetweenTwoDates(
                dateFilter?.startDate,
                dateFilter?.endDate,
            );
        };
    }

    get plannedROI() {
        return this.estimatedValue - this.plannedCost;
    }

    // the total hours estimated derived from this work
    get totalHoursEstimate() {
        let total = 0;

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total += workItem.hoursEstimate;
            });
        }

        return total;
    }

    // the total hours complete derived from this work and its workItems
    get totalHoursComplete() {
        let total = 0;

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total += workItem.hoursComplete;
            });
        }

        return total;
    }

    // the total hours remaining derived from this work and its workItems
    get totalHoursRemaining() {
        let total = 0;

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total += workItem.hoursRemaining;
            });
        }

        return total;
    }

    // the total points complete derived from this work
    get totalPointsComplete() {
        let total = 0;

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total +=
                    workItem.status === this.workItemDoneStatus
                        ? workItem.pointsEstimate
                        : 0;
            });
        }

        return total;
    }

    // the total points remaining derived from this work
    get totalPointsRemaining() {
        let total = 0;

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total +=
                    workItem.status !== this.workItemDoneStatus
                        ? workItem.pointsEstimate
                        : 0;
            });
        }

        return total;
    }

    get totalEstimatedPoints() {
        let total = 0;
        this.workItems.forEach((workItem) => {
            total += workItem.pointsEstimate;
        });
        return total;
    }

    // How many points needs to be completed per work day to stay on schedule
    get requiredVelocityPerDay() {
        return (
            (
                this.totalEstimatedPoints / this.estimatedDurationInCalendarDays
            )?.toFixed(2) * 1
        );
    }

    get requiredVelocityPerDayHours() {
        return (
            (
                this.totalHoursEstimate / this.estimatedDurationInCalendarDays
            )?.toFixed(2) * 1
        );
    }

    // the hours remaining against the estimate to complete this work
    get hoursRemaining() {
        return this.hoursEstimate - this.hoursComplete;
    }

    // get the abbreviated project name
    get abbreviatedName() {
        if (this.name) {
            const strippedName = this.name.replace(/[^a-zA-Z\d\s:]|/g, '');
            const namePieces = strippedName.split(' ');
            let abbreviatedName = '';
            for (var i = 0; i < namePieces.length; i++) {
                if (namePieces[i][0]) {
                    abbreviatedName += namePieces[i][0];
                }
            }

            return abbreviatedName;
        } else {
            return 'NP';
        }
    }

    get nonLaborExpensesTotal() {
        let total = 0;
        if (this.nonLaborExpenses?.length) {
            this.nonLaborExpenses.forEach((cost) => {
                total += cost.totalCost;
            });
        }

        return total;
    }

    get nonLaborExpensesTotalToDate() {
        const today = new Date();
        let total = 0;
        if (this.nonLaborExpenses?.length) {
            this.nonLaborExpenses.forEach((cost) => {
                if (cost.effectiveDate && cost.effectiveDate <= today) {
                    total += cost.totalCost;
                }
            });
        }

        return total;
    }

    get formattedLabels() {
        let labels = this.labels.map((label) => label.name);
        return labels.join(', ');
    }

    get formattedLinesOfBusiness() {
        let lobs = this.linesOfBusiness.map((lob) => lob.name);
        return lobs.join(', ');
    }

    get totalPointsWithDateFilter() {
        return (dateFilter) => {
            if (dateFilter?.startDate && dateFilter.endDate) {
                const totalPoints = this.totalEstimatedPoints;

                if (!totalPoints) {
                    return 0;
                }

                const startDate =
                    this.actualStartDate || this.estimatedStartDate;
                const endDate =
                    this.actualCompletionDate || this.estimatedCompletionDate;

                const dateOverlaps = Calc.dateRangeOverlaps(
                    startDate,
                    endDate,
                    dateFilter?.startDate,
                    dateFilter?.endDate,
                );

                if (!dateOverlaps) {
                    return 0;
                }

                const partialDuration = Calc.durationInCalendarDays(
                    startDate >= dateFilter?.startDate
                        ? startDate
                        : dateFilter?.startDate,
                    endDate <= dateFilter?.endDate
                        ? endDate
                        : dateFilter?.endDate,
                );

                const totalProjectWorkingDays = Calc.durationInWorkDays(
                    startDate,
                    endDate,
                );

                const pointsPerDay =
                    this.totalEstimatedPoints / totalProjectWorkingDays;

                return Math.ceil(partialDuration * pointsPerDay);
            } else {
                return this.totalEstimatedPoints;
            }
        };
    }

    get plannedCostToDate() {
        let total = 0;

        this.resourceTimelines.forEach((timeline) => {
            total += timeline.totalCostToDate;
        });

        if (this.projectProfiles?.length) {
            this.projectProfiles.forEach((projectProfile) => {
                total += projectProfile.totalCostToDate;
            });
        }

        total += this.nonLaborExpensesTotalToDate;

        return total;
    }

    get plannedCostToComplete() {
        return this.plannedCostBetweenTwoDates(
            new Date(),
            this.estimatedCompletionDate,
        );
    }

    get costToDatePercentage() {
        return this.plannedCostToDate && this.estimatedCost
            ? ((this.plannedCostToDate / this.estimatedCost) * 100).toFixed(0) *
            1
            : 0;
    }

    nonLaborExpensesBetweenTwoDates(startDate, endDate) {
        let total = 0;
        if (this.nonLaborExpenses?.length) {
            this.nonLaborExpenses.forEach((cost) => {
                if (
                    moment(cost.effectiveDate).isBetween(
                        startDate,
                        endDate,
                        undefined,
                        '[]',
                    )
                ) {
                    total += cost.totalCost;
                }
            });
        }
        return total;
    }

    plannedLaborCostBetweenTwoDates(startDate, endDate) {
        // loop through all resource timelines and get their plannedCostBetweenTwoDates
        let total = 0;
        if (this.resourceTimelines?.length) {
            this.resourceTimelines.forEach((resourceTimeline) => {
                total += resourceTimeline.plannedCostBetweenTwoDates(
                    startDate,
                    endDate,
                );
            });
        }
        if (this.projectProfiles?.length) {
            this.projectProfiles.forEach((projectProfile) => {
                total += projectProfile.plannedCostBetweenTwoDates(
                    startDate,
                    endDate,
                );
            });
        }
        return total;
    }

    plannedCostBetweenTwoDates(startDate, endDate) {
        let total = this.plannedLaborCostBetweenTwoDates(startDate, endDate);
        total += this.nonLaborExpensesBetweenTwoDates(startDate, endDate);
        return total;
    }

    expectedPointsCompletedByDate(date) {
        return (
            (
                (Calc.durationInCalendarDays(this.estimatedStartDate, date) -
                    1) *
                this.requiredVelocityPerDay
            ).toFixed(2) * 1
        );
    }

    expectedHoursCompletedByDate(date) {
        return (
            (Calc.durationInCalendarDays(this.estimatedStartDate, date) - 1) *
            this.requiredVelocityPerDayHours
        ).toFixed(2);
    }

    budgetOverDay() {
        // budget not set
        if (this.estimatedCost === 0) {
            return null;
        }

        // brute force way to see if this works, optimize later
        const durationInDays = Calc.durationInCalendarDays(
            this.estimatedStartDate,
            this.estimatedCompletionDate,
        );

        for (let i = 0; i <= durationInDays; i++) {
            const dateBeingChecked = new Date(this.estimatedStartDate);
            dateBeingChecked.setDate(dateBeingChecked.getDate() + i + 1);

            // run the budget check
            const plannedCost = this.plannedCostBetweenTwoDates(
                this.estimatedStartDate,
                dateBeingChecked,
            );
            if (plannedCost > this.estimatedCost) {
                return dateBeingChecked.toISOString();
            }
        }
        return null;
    }

    plannedRevenueBetweenTwoDates(startDate, endDate) {
        // loop through all resource timelines and get their plannedCostBetweenTwoDates
        let total = 0;
        if (this.resourceTimelines) {
            this.resourceTimelines.forEach((resourceTimeline) => {
                total += resourceTimeline.plannedRevenueBetweenTwoDates(
                    startDate,
                    endDate,
                );
            });
        }

        return total;
    }

    timelineStatus(widthOfDayInPixels) {
        //expected points or hours to be complete
        const expected = this.trackProjectsBy === "Points"
            ? this.expectedPointsCompletedByDate(new Date())
            : this.expectedHoursCompletedByDate(new Date());

            const behindSchedule = this.trackProjectsBy === "Points"
            ? this.totalPointsComplete < expected
            : this.totalHoursComplete < expected;

            const behindScheduleDays = this.trackProjectsBy === "Points"
            ? Math.ceil(
                (expected - this.totalPointsComplete) /
                this.requiredVelocityPerDay,
            )
            : Math.ceil(
                (expected - this.totalHoursComplete) /
                this.requiredVelocityPerDayHours,
            );

        const estimatedCostPerDay =
            this.estimatedCost / this.estimatedDurationInCalendarDays;
        const expectedAmountSpent =
            estimatedCostPerDay *
            Calc.durationInCalendarDays(this.estimatedStartDate, new Date());
        const outOfBudgetDay = this.budgetOverDay();

        return {
            behindSchedule: behindSchedule,
            behindScheduleDays: behindScheduleDays,
            overbudget: expectedAmountSpent < this.actualCost,
            overbudgetAmount: currencyFormatter.format(
                this.actualCost - expectedAmountSpent,
            ),
            outOfBudget: !!outOfBudgetDay,
            outOfBudgetDay,
            outOfBudgetWidthInPixels:
                Calc.durationInCalendarDays(
                    outOfBudgetDay,
                    this.estimatedCompletionDate,
                ) * widthOfDayInPixels,
        };
    }

    get progress() {
        return this.totalEstimatedPoints > 0
            ? (
                (this.totalPointsComplete / this.totalEstimatedPoints) *
                100
            )?.toFixed(2) * 1
            : 0;
    }

    get estimatedStartDateForProjectTable() {
        return (dateFilter) => {
            const dateOverlaps = Calc.dateRangeOverlaps(
                this.estimatedStartDate,
                this.estimatedCompletionDate,
                dateFilter?.startDate,
                dateFilter?.endDate,
            )?.startDate;
            return dateOverlaps;
        };
    }

    get estimatedCompletionDateForProjectTable() {
        return (dateFilter) => {
            const dateOverlaps = Calc.dateRangeOverlaps(
                this.estimatedStartDate,
                this.estimatedCompletionDate,
                dateFilter?.startDate,
                dateFilter?.endDate,
            )?.endDate;
            return dateOverlaps;
        };
    }
}
